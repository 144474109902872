import {debounce, merge, set} from "lodash";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    NotificationDisplayType,
    NotificationEventType,
    useAuthEvents,
    useDeepCompareCallback,
    useDeepCompareEffect,
    usePostNotification,
    usePrevious
} from "./index";
import crudClient, {AUTHENTICATION_FAILED, CrudParams, CrudTypes} from "../clients/crudClient";
import {useGlobalParam} from "./GlobalParamsHooks";
import {useI18next} from "../i18n";
import {AuthEventType} from "../components/providers";

/**
 * CRUD Options
 *
 * Additional options for [CRUD](/page?path=/docs/cudareactapp-crud--page) fetch requests.
 */
export interface CrudOptions {
    /** if true, no dialog notification is triggered if there is an error response. */
    quietErrors?: boolean,
    /** if true, the fetch request is either resolved as normal, or a SubmissionError is thrown. */
    formPromise?: boolean,
    /** whether to cache data. */
    cache?: boolean
}

/**
 * CRUD Error object. Returned by CRUD requests for all non-successful requests.
 */
export interface CrudError {
    statusCode?: number,
    message: string,
    body?: any,
    error: Error
}

/**
 * CRUD perform fetch method. The provided parameters are merged with those originally provided to the crud fetch request.
 * @param overrideParams if provided, these params are merged on top of the params from the original crud hook call.
 * @param overrideOptions if provided, these options are merged on top of the options from the original crud hook call.
 * @param overrideUrl if provided, this URL is used instead of any that was configured in the original crud hook call.
 */
export type PerformFetch<DataModel = any> = (overrideParams?: CrudParams, overrideOptions?: CrudOptions, overrideUrl?: string) => Promise<{
    data?: DataModel,
    error?: CrudError
}>;

/**
 * The common response from useCrud* hooks
 * @param 0 The object describing the returned data (or error).
 * @param 1 if true, a requests is currently in progress.
 * @param 2 function to initiate an immediate fetch (optionally with params and option overrides).
 * @param 3 function to clear all data, error and loading state.
 */
export type CrudHookResponse<DataModel> = [{ data?: DataModel, error?: CrudError }, boolean, PerformFetch, () => void];

// TODO: Need to review/update the overview CRUD page, then update these descriptions.
/**
 * Hook that provides the data and state from a crud fetch, that can be performed on demand via the returned callback
 *
 * @function
 * @param type the URL for the request.
 * @param url the [CRUD](/page?path=/docs/cudareactapp-crud--page) type (CrudTypes.GET|CrudTypes.CREATE|CrudTypes.UPDATE|CrudTypes.DELETE!ACTION).
 * @param params optional parameters to include in [CRUD](/page?path=/docs/cudareactapp-crud--page) request.
 * @param options additional options for [CRUD](/page?path=/docs/cudareactapp-crud--page) fetch requests.
 * @returns the data/error, loading status, and perform fetch action for the given [CRUD](/page?path=/docs/cudareactapp-crud--page) resource.
 */
export const useCrudFetch = <DataModel = any>(type: CrudTypes, url?: string, params?: CrudParams, options?: CrudOptions): CrudHookResponse<DataModel> => {
    const [globalParams] = useGlobalParam();
    const {activeLanguage} = useI18next();
    const [state, setState] = useState<{
        data: DataModel | undefined,
        loading: boolean,
        error: CrudError | undefined
    }>({
        data: undefined,
        loading: false,
        error: undefined
    });
    const postAuthEvent = useAuthEvents();
    const postNotification = usePostNotification();
    const abortController = useRef(new AbortController());
    const clearState = useCallback(() => setState({data: undefined, loading: false, error: undefined}), []);

    const performFetch: PerformFetch<DataModel> = useDeepCompareCallback((overrideParams, overrideOptions, overrideUrl) => {
        const finalParams = merge({}, params, overrideParams);
        const finalOptions = merge({}, options, overrideOptions);
        const finalUrl = overrideUrl || url;

        // Abort any in progress fetches, before scheduling new fetch
        abortController.current.abort();
        abortController.current = new AbortController();

        setState((currentState) => ({...currentState, loading: true}));
        const setAuthorization = globalParams?.auth0?.stsToken?.access_token && !finalUrl?.startsWith("http");

        return crudClient(
            type,
            finalUrl,
            finalParams,
            globalParams,
            {
                signal: abortController.current.signal,
                headers: setAuthorization ? {
                    "Accept-Language": activeLanguage,
                    "Authorization": "Bearer " + globalParams?.auth0?.stsToken?.access_token
                } : {"Accept-Language": activeLanguage}
            }
        ).then((response: { data?: DataModel }) => {
            setState({data: response.data, loading: false, error: undefined});

            return {data: response.data};
        }).catch((error: any) => {
            if (error.name === 'AbortError' || error.code === DOMException.ABORT_ERR) {
                return {data: undefined};
            }

            const message = (typeof error === "string" ? error : (error && error.message));
            if (!finalOptions.quietErrors && error !== AUTHENTICATION_FAILED) {
                postNotification({
                    event: NotificationEventType.SHOW,
                    display: NotificationDisplayType.DIALOG,
                    params: {content: message}
                });
            }
            postAuthEvent({type: AuthEventType.ERROR, params: error});

            const crudError = {
                statusCode: error?.statusCode,
                body: error?.body,
                message,
                error
            };
            setState({error: crudError, loading: false, data: undefined});

            if (finalOptions?.formPromise && error?.body?.errors) {
                let violations = {};
                // take the shortest errors last, this will make sure the main error for a field
                // takes precedence over any sub errors for that same field
                Object.keys(error.body.errors).sort().reverse().forEach((field) => {
                    set(violations, field, error.body.errors[field]);
                });

                return Promise.reject(violations);
            }

            return {error: crudError};
        });
    }, [type, url, params, options, globalParams, activeLanguage]);

    // Abort active requests if hook is unmounted.
    useEffect(() => () => abortController.current.abort(), []);

    return [
        {data: state.data, error: state.error},
        state.loading,
        performFetch,
        clearState
    ];
};

/**
 * CRUD Subscribe Options.
 * @param {number} pollInterval interval time between repeat requests (if not already repeated), in milliseconds.
 */
export interface CrudSubscribeOptions {
    /** options for the underlying crud requests */
    crudOptions?: CrudOptions,
    /** interval time between repeat requests (if not already repeated), in milliseconds. */
    pollInterval?: number
    /** time in ms to debounce each change to the provided params and options, before performing a new CRUD request and restarting the poll interval */
    debounceWait?: number
}

/**
 * The response from useCrudSubscription hook
 * @param 0 The object describing the returned data (or error).
 * @param 1 if true, a requests is currently in progress.
 * @param 2 function to initiate an immediate fetch.
 * @param 3 function to clear all data, error and loading state.
 */
export type CrudSubscriptionResponse<DataModel> = [{
    data?: DataModel,
    error?: CrudError
}, boolean, () => void, () => void];

/**
 * Simple hook that sets up a crud subscription and returns the data.
 *
 * A crud subscription is a request that is repeated per a poll interval. However it can also be manually invoked at any time, and will auto refresh when
 * global params are changed.
 *
 * @function
 * @param type the [CRUD](/page?path=/docs/cudareactapp-crud--page) type (CrudTypes.GET|CrudTypes.CREATE|CrudTypes.UPDATE|CrudTypes.DELETE!ACTION) either for all [CRUD](/page?path=/docs/cudareactapp-crud--page) subscriptions, or per [CRUD](/page?path=/docs/cudareactapp-crud--page) subscription.
 * @param url the [CRUD](/page?path=/docs/cudareactapp-crud--page) resource to create a subscription for. While not provided. no new requests are made. However the data from the last performed request (if any) is still returned.
 * @param params optional parameters to include in [CRUD](/page?path=/docs/cudareactapp-crud--page) request/s.
 * @param options optional options for configuring the useCrudSubscription hook.
 * @returns
 */
export const useCrudSubscription = <DataModel = any>(
    type: CrudTypes,
    url?: string,
    params?: CrudParams,
    options?: CrudSubscribeOptions
): CrudSubscriptionResponse<DataModel> => {
    const {crudOptions, pollInterval = 60000, debounceWait = 0} = options || {};
    const [response, loading, performFetch, clearState] = useCrudFetch(type, url, params, crudOptions);
    const pollState = useRef({
        activeLoopId: 0,
        activeTimeoutId: 0,
        pollInterval,
        performFetch
    });
    pollState.current.pollInterval = pollInterval;
    pollState.current.performFetch = performFetch;

    const {renewLoop, startLoop, cleanUp} = useMemo(() => {
        const scheduleNextCall = (loopId: number) => {
            if (pollState.current.activeLoopId === loopId) {
                pollState.current.activeTimeoutId = window.setTimeout(
                    () => debouncePerformCall?.(loopId),
                    pollState.current.pollInterval
                );
            }
        };
        const debouncePerformCall = debounce((loopId: number) => {
            if (pollState.current.activeLoopId === loopId) {
                pollState.current.performFetch?.().finally(() => scheduleNextCall(loopId));
            }
        }, debounceWait);

        const cleanUp = () => {
            pollState.current.activeLoopId++;
            pollState.current.activeTimeoutId && clearTimeout(pollState.current.activeTimeoutId);
            pollState.current.activeTimeoutId = 0;
        };

        const startLoop = () => {
            cleanUp();
            debouncePerformCall(++pollState.current.activeLoopId);
        };
        const renewLoop = () => {
            cleanUp();
            scheduleNextCall(++pollState.current.activeLoopId);
        };

        return {renewLoop, cleanUp, startLoop};
    }, []);

    const oldPollInterval = usePrevious(pollInterval);
    const oldPerformFetch = usePrevious(performFetch);
    useDeepCompareEffect(() => {
        // If only poll interval is changed and the new poll interval is longer, just reset the loop timeout
        if (url && oldPollInterval && pollInterval > oldPollInterval && oldPerformFetch === performFetch) {
            renewLoop();
            // Otherwise, start a new loop with an immediate call
        } else if (url) {
            startLoop();
        }
        return () => cleanUp();
    }, [url, performFetch, pollInterval]);

    return [response, loading, startLoop, clearState];
};

const crudCache: { [key: string]: any } = {};

/**
 * Simple hook that provides props data from a crud resource.
 *
 * This uses useCrudFetch, immediately performing the fetch request after load (and on after debounceWait for each change to arguments).
 * Optionally, you can also set the data to be locally cached (by url).
 *
 * @function
 * @param url the [CRUD](/page?path=/docs/cudareactapp-crud--page) resource. If not provided, the request is not perfomed.
 * @param params parameters to include in [CRUD](/page?path=/docs/cudareactapp-crud--page) request.
 * @param options options for configuring the useCrudProps hook. "quietErrors" is set to true by default.
 * @param cache if true, the data returned from the reuest is cached,
 * @param debounceWait time in ms to debounce each change to resource/params/options, before performing a new fetch request.
 * @returns the data for the given [CRUD](/page?path=/docs/cudareactapp-crud--page) resource.
 */
export const useCrudProps = <DataModel = any>(url?: string, params?: CrudParams, options?: CrudOptions, cache = false, debounceWait = 0): CrudHookResponse<DataModel> => {
    const [response, loading, performFetch, clearState] = useCrudFetch<DataModel>(CrudTypes.GET, url, params, {quietErrors: true, ...(options || {})});
    const performFetchRef = useRef(performFetch);
    performFetchRef.current = performFetch;
    const debouncePerformFetch = useCallback(debounce((params?: CrudParams, options?: CrudOptions) => performFetchRef.current(params, options), debounceWait), [debounceWait]);

    let resolvedResponse = response;
    if (cache && url && !resolvedResponse?.data) {
        try {
            resolvedResponse.data = crudCache[url] || undefined;
        } catch (error) {
            // Do nothing if it can't be parsed. Just set the resolvedData to nothing, as this will trigger a new request.
            resolvedResponse.data = undefined;
        }
    }

    useDeepCompareEffect(() => {
        if (url && (!cache || !resolvedResponse?.data)) {
            debouncePerformFetch();
        }
    }, [url, params, options]);

    useEffect(() => {
        if (resolvedResponse?.data && cache && url) {
            crudCache[url] = resolvedResponse.data;
        }
    }, [resolvedResponse?.data]);

    return [resolvedResponse, loading, performFetch, clearState];
};